











import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import i18n from '@/services/i18n';
import { defaultNumberInputFormats, fallbackFormatter, fallbackNumberInputFormats, fallbackParser } from '../common';
import { Utils } from '../types';


type CharType = 'minus-sign' | 'digit' | 'dot';


const modelChangeEvent = 'change';

const noModelValue = Symbol('modules/budget/staffing-table/NumberInput :: no value');
Object.freeze(noModelValue);


const getCharType = (value: string): (CharType | null) => {
    switch (value) {
        case '-':
            return 'minus-sign';

        case '.':
        case ',':
            return 'dot';

        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
            return 'digit';

        default:
            return null;
    }
};

const getCharTypesBeforePosition = (value: string, position: number): Array<CharType> => {
    const result: Array<CharType> = [];

    const count = Math.min(value.length, position);
    for (let index = 0; index < count; index++) {
        const char = value[index];
        const type = getCharType(char);
        if (type !== null) {
            result.push(type);
        }
    }

    return result;
};


@Component
export default class NumberInput extends Vue {
    // region Model, properties
    @Model(modelChangeEvent, {
        type: Number,
        required: false,
        default: noModelValue
    })
    public readonly value!: number | null | symbol;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly formats!: Utils.NumberInput.Formats | null;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly ignoreDefaultFormats!: boolean;

    @Prop({
        type: [Number, String],
        required: false,
        default: null
    })
    public readonly min!: string | number | null;

    public get usedMin(): number | null {
        const min = this.min;
        if (min === null) {
            return null;
        }

        if (typeof min === 'number') {
            if (min.isFinite) {
                return min;
            }
            return null;
        }

        let result: number | null;
        try {
            result = parseFloat(min);
        } catch (e) {
            console.error('modules/budget/staffing-table/NumberInput', e);
            result = null;
        }
        return result;
    }

    @Prop({
        type: [Number, String],
        required: false,
        default: null
    })
    public readonly max!: string | number | null;

    public get usedMax(): number | null {
        const max = this.max;
        if (max === null) {
            return null;
        }

        if (typeof max === 'number') {
            if (max.isFinite) {
                return max;
            }
            return null;
        }

        let result: number | null;
        try {
            result = parseFloat(max);
        } catch (e) {
            console.error('modules/budget/staffing-table/NumberInput', e);
            result = null;
        }
        return result;
    }

    @Prop({
        type: String,
        required: false,
        default: () => null,
    })
    public placeholder!: string | null;
    // endregion


    // region Lifecycle
    protected created() {
        this.setLocalValueFromValue();
        this.setFieldStringValueFromLocal();

        this.$watch('value', () => {
            this.setLocalValueFromValue();
        });

        this.$watch('fieldNumberValue', () => {
            if (this.localValue !== this.fieldNumberValue) {
                this.localValue = this.fieldNumberValue;
            }
        });

        this.$watch('localValue', () => {
            if (this.localValue !== null) {
                const min = this.usedMin;
                const max = this.usedMax;
                if ((min === null) || (max === null) || (min <= max)) {
                    if ((min !== null) && (this.localValue < min)) {
                        this.$nextTick(() => {
                            this.localValue = min;
                        });
                        return;
                    }
                    if ((max !== null) && (this.localValue > max)) {
                        this.$nextTick(() => {
                            this.localValue = max;
                        });
                        return;
                    }
                }
            }

            this.setFieldStringValueFromLocal();

            if (this.value !== this.localValue) {
                this.$emit(modelChangeEvent, this.localValue);
            }
        });
    }
    // endregion


    // region Used formats
    public get usedFormats(): Utils.NumberInput.Formats {
        const formats = this.formats;
        if (formats !== null) {
            return formats;
        }

        if (this.ignoreDefaultFormats) {
            return fallbackNumberInputFormats;
        }

        return defaultNumberInputFormats;
    }

    public get locale(): string {
        return i18n.locale;
    }

    public get usedFormatter(): Utils.NumberInput.Formatter {
        const locale = this.locale;

        const formats = this.usedFormats;

        if (formats.getFormatter) {
            const result = formats.getFormatter(locale);
            if (result) {
                return result;
            }
        }

        if (formats.localeToFormatterMap) {
            const result = formats.localeToFormatterMap[locale];
            if (result) {
                return result;
            }
        }

        if (formats.defaultFormatter) {
            return formats.defaultFormatter;
        }

        return fallbackFormatter;
    }

    public get usedParser(): Utils.NumberInput.Parser {
        const locale = this.locale;

        const formats = this.usedFormats;

        if (formats.getParser) {
            const result = formats.getParser(locale);
            if (result) {
                return result;
            }
        }

        if (formats.localeToParserMap) {
            const result = formats.localeToParserMap[locale];
            if (result) {
                return result;
            }
        }

        if (formats.defaultParser) {
            return formats.defaultParser;
        }

        return fallbackParser;
    }
    // endregion


    // region Utils
    public get element(): HTMLInputElement | null {
        const elementComponent = (this.$refs.element as unknown);
        if (elementComponent instanceof Object) {
            const element = (elementComponent as Vue).$el;
            if (element instanceof HTMLInputElement) {
                return element;
            }
        }
        return null;
    }

    public useElement(use: (element: HTMLInputElement) => void) {
        const element = this.element;
        if (element !== null) {
            use(element);
        }
    }
    // endregion


    // region Local value
    public fieldStringValue = '';

    public get fieldNumberValue(): number | null {
        const formatter = this.usedFormatter;
        const parser = this.usedParser;

        return parser(formatter(parser(this.fieldStringValue)));
    }

    public localValue: number | null = null;

    public setLocalValueFromValue() {
        // noinspection SuspiciousTypeOfGuard
        if ((typeof this.value !== 'symbol') && (this.localValue !== this.value)) {
            this.localValue = this.value;
        }
    }

    public setFieldStringValueFromLocal() {
        const value = ((this.localValue !== null) ? this.usedFormatter(this.localValue) || '' : '');
        if (this.fieldStringValue !== value) {
            this.fieldStringValue = value;
        }
    }

    public onFieldStringValueChange(newValue: unknown) {
        let typedNewValue: string;
        if (typeof newValue === 'string') {
            typedNewValue = newValue;
        } else {
            typedNewValue = String(newValue);
        }

        const charsBeforeSelection = ((): Array<CharType> | null => {
            let result: Array<CharType> | null = null;

            this.useElement((element) => {
                const selectionStart = (element.selectionStart as unknown as (number | null));
                if (typeof selectionStart === 'number') {
                    const value = (element.value as unknown);
                    if ((typeof value === 'string') && (selectionStart < value.length)) {
                        result = getCharTypesBeforePosition(value, selectionStart);
                    }
                }
            });

            return result;
        })();

        this.fieldStringValue = typedNewValue;

        if (charsBeforeSelection !== null) {
            setTimeout(() => {
                this.useElement((element) => {
                    if (charsBeforeSelection.length === 0) {
                        element.selectionDirection = 'forward';
                        element.selectionStart = 0;
                        element.selectionEnd = element.selectionStart;
                    } else {
                        const value = (element.value as unknown);
                        if (typeof value === 'string') {
                            if (value.length > 0) {
                                let valueIndex = 0;

                                while ((valueIndex < value.length) && (charsBeforeSelection.length > 0)) {
                                    const char = value.charAt(valueIndex);
                                    const charType = getCharType(char);
                                    valueIndex++;

                                    if (charType === charsBeforeSelection[0]) {
                                        charsBeforeSelection.splice(0, 1);
                                    }
                                }

                                element.selectionDirection = 'forward';
                                element.selectionStart = Math.min(valueIndex, value.length);
                                element.selectionEnd = element.selectionStart;
                            } else {
                                element.selectionDirection = 'forward';
                                element.selectionStart = 0;
                                element.selectionEnd = element.selectionStart;
                            }
                        }
                    }
                });
            });
        }
    }

    public blur() {
        this.setFieldStringValueFromLocal();
    }
    // endregion
}
