













import { Component, Prop, Vue } from 'vue-property-decorator';


type StyleObject = Record<'width' | 'height' | string, string>;


const DEFAULT_VIEW_BOX = '0 0 24 24';
const MDI_VIEW_BOX = '0 0 24 24';


@Component
export default class SvgIcon extends Vue {
    @Prop({
        type: String,
        required: true,
    })
    public value!: string;

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public title!: string | null;


    // region View box
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public viewBox!: string | null;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    public mdiViewBox!: boolean;

    public get actualViewBox(): string {
        if (this.viewBox !== null) {
            try {
                const strings = this.viewBox.trim().split(' ');
                if (strings.length !== 4) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error(`unexpected part count ${strings.length}`);
                }

                const numbers = strings.map((viewBoxPartString, index) => {
                    const trimmed = viewBoxPartString.trim();
                    const number = parseInt(viewBoxPartString);
                    if (number.toString() !== trimmed) {
                        throw new Error(`cannot parse part #${index} "${trimmed}" as integer`);
                    }
                    return number;
                });

                return numbers.join(' ');
            } catch (e) {
                console.error(`Invalid viewBox "${this.viewBox}": ${e}`);
            }
        }

        if (this.mdiViewBox) return MDI_VIEW_BOX;

        return DEFAULT_VIEW_BOX;
    }
    // endregion


    @Prop({
        type: [String, Number],
        required: false,
        default: () => null,
    })
    public size!: string | number | null;


    public get style(): StyleObject {
        const result: StyleObject = {};

        const size = this.size;
        switch (typeof size) {
            case 'string':
                result.width = size;
                result.height = size;
                break;
            case 'number':
                result.width = `${size}px`;
                result.height = `${size}px`;
                break;
        }

        return result;
    }
}
