















import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class LabeledPane extends Vue {
    // region Модель, свойства
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public label!: string | null;
    // endregion


    private get actualLabel(): string {
        return this.label ?? '';
    }
}
