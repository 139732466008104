var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'modules',
        'budget',
        'staffing-table',
        'components',
        'ListItem',
        (_vm.expanded ? 'expanded' : ''),
        (_vm.active ? 'active' : '') ]},[_c('div',{class:[
            'main-row',
            (_vm.hasCollapsibleSlot ? 'collapsible' : ''),
            (_vm.variant === null ? '' : ("back-" + _vm.variant)) ]},[_c('div',{class:[
                'main',
                (_vm.expanded ? 'expanded' : ''),
                (_vm.clickable ? 'clickable' : '') ],on:{"click":_vm.onMainClick}},[_vm._t("default")],2),(_vm.hasCollapsibleSlot)?_c('flat-button',{staticClass:"collapse-button",attrs:{"title":(_vm.expanded ? _vm.i18n.translate('collapse') : _vm.i18n.translate('expand'))},on:{"click":_vm.onCollapseButtonClick}},[_c('svg-icon',{class:['icon', (_vm.expanded ? 'expanded' : '')],attrs:{"value":_vm.iconPath}})],1):_vm._e()],1),_c('transition',{attrs:{"name":"collapsible-row"},on:{"before-enter":_vm.onBeforeEnter,"enter":_vm.onEnter,"after-enter":_vm.onAfterEnter,"before-leave":_vm.onBeforeLeave,"leave":_vm.onLeave,"after-leave":_vm.onAfterLeave}},[(_vm.hasCollapsibleSlot && _vm.expanded)?_c('div',{staticClass:"collapsible-row"},[_c('div',{staticClass:"top-border"}),_vm._t("collapsible")],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }