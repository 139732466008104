import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Vue } from 'vue-property-decorator';
import i18nDb from '@/services/i18n-db';
import { Ax } from '@/utils';
import I18n from '../I18n';
import { Org } from '../types';
import svgIcons from './svg-icons';


export default class ComponentBase extends Vue {
    constructor(i18nBase: string) {
        super();

        this.i18n = new I18n(i18nBase);
    }


    // region Lifecycle
    protected beforeCreate() {}

    protected created() {}

    protected beforeMount() {}

    protected mounted() {}

    protected beforeUpdate() {}

    protected updated() {}

    protected beforeDestroy() {}

    protected destroyed() {}
    // endregion


    // region Локализация
    public i18n: I18n;

    public i18nDb = i18nDb;

    protected get locale(): string {
        return this.i18n.locale.toLowerCase();
    }

    protected get isEnglish(): boolean {
        return (this.locale === 'en');
    }

    protected get isKazakh(): boolean {
        return (this.locale === 'kk');
    }

    // noinspection JSUnusedGlobalSymbols
    protected get isRussian(): boolean {
        return (this.locale === 'ru');
    }

    protected localize<T>(en: () => T, kk: () => T, ru: () => T): T {
        if (this.isEnglish) {
            return en();
        } else if (this.isKazakh) {
            return kk();
        } else {
            return ru();
        }
    }
    // endregion


    // region Утилиты
    protected toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-right',
            autoHideDelay: 5000,
            appendToast: true,
        });
    }


    protected prepareUrl(baseUrl: string, parameterMap?: Array<[string, unknown]>): string {
        const resultParts = [baseUrl];

        const prepareValue = (value: unknown): string => {
            if ((value === undefined) || (value === null)) {
                return '';
            } else {
                return encodeURIComponent(String(value));
            }
        };

        let firstParam = true;
        const addParameter = (key: string, value: unknown) => {
            if (firstParam) {
                resultParts.push('?');
                firstParam = false;
            } else {
                resultParts.push('&');
            }

            resultParts.push(key, '=', prepareValue(value));
        }

        if (parameterMap !== undefined) {
            parameterMap.forEach((keyValuePair) => {
                const key = keyValuePair[0];
                const value = keyValuePair[1];
                if (Array.isArray(value)) {
                    value.forEach((valuePart) => {
                        addParameter(key, valuePart);
                    })
                } else {
                    addParameter(key, value);
                }
            });
        }

        return resultParts.join('');
    }

    protected openUrl(url: string, target?: '_blank' | '_parent' | '_self' | '_top' | string, replace?: boolean) {
        const actualTarget = target ?? '_blank';

        // noinspection SpellCheckingInspection
        window.open(url, actualTarget, 'noopener noreferrer', replace);
    }

    protected request<T>(
        config: AxiosRequestConfig,
        onSuccess: (data: T, response: AxiosResponse<T>) => void,
        errorTitleDef?: (() => string) | string,
        final?: () => void,
    ) {
        Ax<T>(
            config,
            onSuccess,
            (error) => {
                let title: string;
                if (errorTitleDef === undefined) {
                    title = '';
                } else if (typeof errorTitleDef === 'string') {
                    title = this.i18n.translate(errorTitleDef);
                } else {
                    title = errorTitleDef();
                }

                this.toast('danger', title, error.toString());
            },
            final,
        );
    }


    protected useOrg<T>(org: Org, useGu: (guOrg: Org.Gu) => T, useKgkp: (kgkpOrg: Org.Kgkp) => T): T {
        if (org.type === 'GU') {
            return useGu(org);
        } else {
            return useKgkp(org);
        }
    }

    protected svgIcons = svgIcons;

    /**
     * @param date
     * @protected
     * @example '0000-00-00'
     */
    protected formatDateForRequest(date: Date | number): string {
        let dateObject: Date;
        if (typeof date === 'number') {
            dateObject = new Date(date);
        } else {
            dateObject = date;
        }

        const resultParts: Array<string> = [];

        const year = dateObject.getFullYear().toString();
        switch (year.length) {
            case 1:
                resultParts.push('000');
                break;
            case 2:
                resultParts.push('00');
                break;
            case 3:
                resultParts.push('0');
                break;
        }
        resultParts.push(year);

        resultParts.push('-');

        const month = (dateObject.getMonth() + 1).toString();
        if (month.length === 1) {
            resultParts.push('0');
        }
        resultParts.push(month);

        resultParts.push('-');

        const day = dateObject.getDate().toString();
        if (day.length === 1) {
            resultParts.push('0');
        }

        return resultParts.join('');
    }

    /**
     * @param date
     * @protected
     * @example '00:00:00.000'
     */
    protected formatTimeForRequest(date: Date | number): string {
        let dateObject: Date;
        if (typeof date === 'number') {
            dateObject = new Date(date);
        } else {
            dateObject = date;
        }

        const resultParts: Array<string> = [];

        const hours = dateObject.getHours().toString();
        if (hours.length === 1) {
            resultParts.push('0');
        }
        resultParts.push(hours);

        resultParts.push(':');

        const minutes = dateObject.getMinutes().toString();
        if (minutes.length === 1) {
            resultParts.push('0');
        }
        resultParts.push(minutes);

        resultParts.push(':');

        const seconds = dateObject.getSeconds().toString();
        if (seconds.length === 1) {
            resultParts.push('0');
        }
        resultParts.push(seconds);

        resultParts.push('.');

        const milliseconds = dateObject.getMilliseconds().toString();
        switch (milliseconds.length) {
            case 1:
                resultParts.push('00');
                break;
            case 2:
                resultParts.push('0');
                break;
        }
        resultParts.push(milliseconds);

        return resultParts.join('');
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * @param date
     * @protected
     * @example '0000-00-00 00:00:00.000'
     */
    protected formatDateTimeForRequest(date: Date | number): string {
        let dateObject: Date;
        if (typeof date === 'number') {
            dateObject = new Date(date);
        } else {
            dateObject = date;
        }

        const dateString = this.formatDateForRequest(dateObject);
        const timeString = this.formatTimeForRequest(dateObject);
        return `${dateString}-${timeString}`;
    }
    // endregion
}