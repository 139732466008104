var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modules budget staffing-table components ListItemPart",style:({
        'width': _vm.actualWidth,
        'max-width': _vm.maxWidth,
    })},[(_vm.hasTitle)?_c('div',{class:[
            'title',
            (_vm.hasTitleTooltip ? 'has-tooltip' : null) ],attrs:{"title":_vm.titleTooltip}},[_vm._v(_vm._s(_vm.title)+":")]):_vm._e(),_c('div',{class:[
            'text',
            (_vm.hasTextTooltip ? 'has-tooltip' : null)
        ],attrs:{"title":_vm.textTooltip}},[_vm._v(_vm._s(_vm.text))])])}
var staticRenderFns = []

export { render, staticRenderFns }