






























import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '../I18n';


const i18n = new I18n('modules.budget.staffing_table.components.ListItemPart');
const maxWidth = 'calc(100% - 4px)';


@Component({
    computed: {
        i18n() {
            return i18n
        }
    }
})
export default class ListItemPart extends Vue {
    // region Title
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public title!: string | null;

    private get hasTitle(): boolean {
        return ((this.title !== null) && (this.title.length > 0));
    }

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public titleTooltip!: string | null;

    private get hasTitleTooltip(): boolean {
        return ((this.titleTooltip !== null) && (this.titleTooltip.length > 0));
    }
    // endregion


    // region Text
    @Prop({
        type: String,
        required: true,
    })
    public text!: string;

    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public textTooltip!: string | null;

    private get hasTextTooltip(): boolean {
        return ((this.textTooltip !== null) && (this.textTooltip.length > 0));
    }
    // endregion


    // region Width
    @Prop({
        type: String,
        required: false,
        default: null,
    })
    public width!: string | null;

    private maxWidth = maxWidth;

    private get actualWidth(): string { return this.width ?? this.maxWidth; }
    // endregion
}
