var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'modules', 'budget', 'staffing-table', 'components', 'Collapsible',
        (_vm.localValue ? 'expanded' : ''),
        (_vm.decorationVariant === null ? '' : _vm.decorationVariant)
    ]},[_c('div',{staticClass:"head",on:{"click":_vm.onCollapseExpandButtonClick}},[_c('div',{staticClass:"icon-area"},[_c('svg-icon',{class:[
                    'collapse-expand-icon',
                    (_vm.localValue ? 'rotated' : '')
                ],attrs:{"value":_vm.collapseExpandIcon}})],1),_c('div',{staticClass:"head-content"},[_vm._t("head",function(){return [_vm._v(" "+_vm._s(_vm.head)+" ")]})],2)]),_c('transition',{attrs:{"name":"content"},on:{"before-enter":_vm.onBeforeEnter,"enter":_vm.onEnter,"after-enter":_vm.onAfterEnter,"before-leave":_vm.onBeforeLeave,"leave":_vm.onLeave,"after-leave":_vm.onAfterLeave}},[(_vm.localValue)?_c('div',{staticClass:"content",style:({
                overflow: _vm.contentOverflow || '',
            })},[_c('div',{staticClass:"top-border"}),_vm._t("default")],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }